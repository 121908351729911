import type { MetaFunction } from "@remix-run/node"
import { json } from "@remix-run/node"
import { Link } from "@remix-run/react"
import { getEnvironmentMinimal } from "~/utils/environment.server"

export const meta: MetaFunction = () => {
  return [
    { title: "apis.ligonier.org" },
    { name: "description", content: "Ligonier APIs" },
  ]
}

export const loader = async () => {
  const ENV = getEnvironmentMinimal()
  return json({ ENV: { ENVIRONMENT: ENV.ENVIRONMENT } }, 200)
}

export default function Index() {
  return (
    <div style={{ fontFamily: "system-ui, sans-serif", lineHeight: "1.8" }}>
      <>
        <h1>Ligonier APIs</h1>
        <h2 style={{ marginBottom: "0" }}>Contentful Algolia</h2>
        <p style={{ fontSize: "12px", marginTop: "0" }}>
          APIs to update Algolia indexes from Contentful
        </p>
        <ul>
          <li>
            <Link to="/contentful-algolia/web-hook/ui">Web Hook</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Typically handles notifications from Contentful
            </p>
          </li>
          <li>
            <Link to={"/contentful-algolia/full-index/ui"}>Full Index</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Typically runs at scheduled times
            </p>
          </li>
        </ul>
        <h2 style={{ marginBottom: "0" }}>Shopify Algolia</h2>
        <p style={{ fontSize: "12px", marginTop: "0" }}>
          APIs to update Algolia indexes from Shopify
        </p>
        <ul>
          <li>
            <Link to="/shopify-algolia/web-hook/ui">Web Hook</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Typically handles notifications from Shopify
            </p>
          </li>
          <li>
            <Link to={"/shopify-algolia/full-index/ui"}>Full Index</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Typically runs at scheduled times
            </p>
          </li>
        </ul>
        <h2 style={{ marginBottom: "0" }}>RBC</h2>
        <p style={{ fontSize: "12px", marginTop: "0" }}>
          APIs to update RBC student essays
        </p>
        <ul>
          <li>
            <Link to="/rbc/presigned-url/ui">Presigned URL</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Get Presigned URL for uploading student essays
            </p>
          </li>
          <li>
            <Link to={"/rbc/submit-essay/ui"}>Submit Essay</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Send notification that an essay has been submitted
            </p>
          </li>
        </ul>
        <h2 style={{ marginBottom: "0" }}>MISC.</h2>
        <ul>
          <li>
            <Link to="/video/duration/ui">Video duration indexer</Link>
            <p style={{ fontSize: "12px", marginTop: "0" }}>
              Contentful `Video` content&apos;s `duration` indexer
            </p>
          </li>
        </ul>
      </>
    </div>
  )
}
